import { render, staticRenderFns } from "./Spoucecard.vue?vue&type=template&id=42419f10&scoped=true&lang=pug&"
import script from "./Spoucecard.vue?vue&type=script&lang=ts&"
export * from "./Spoucecard.vue?vue&type=script&lang=ts&"
import style0 from "./Spoucecard.vue?vue&type=style&index=0&id=42419f10&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42419f10",
  null
  
)

export default component.exports