import { render, staticRenderFns } from "./Preoverrate.vue?vue&type=template&id=71f81148&scoped=true&lang=pug&"
import script from "./Preoverrate.vue?vue&type=script&lang=ts&"
export * from "./Preoverrate.vue?vue&type=script&lang=ts&"
import style0 from "./Preoverrate.vue?vue&type=style&index=0&id=71f81148&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71f81148",
  null
  
)

export default component.exports